<template>
   <div>

      <!-- Breadcrumb start -->
       <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_2 font_bold mb_23">Travel for work<span
                           class="text_primary">guide</span></h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0" :to="{ name: 'user_home' }">
         Home
        </router-link></li>
                           <li class="breadcrumb-item active" aria-current="page">Travel for work</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <section :class="!company_code ? 'travel_for_work how_else_ergo mb-0' : 'travel_for_work how_else_ergo'">         
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xxl-12">
                  <div class="row">
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'airplane_travel' }">
                              <img src="../assets/images/travel-for-work/airplane-travel.jpg" alt="img"
                                 class="mb_66">                              
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Airplane Travel</h5>
                                 <p class="text-center font_size_18 mb_20 text_balticsea">
                                    Travel tips and tricks to keep you comfy on the go.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'on_the_road' }">
                              <img src="../assets/images/travel-for-work/on-the-road.jpg" alt="img"
                                 class="mb_66">                                
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">On The Road</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Learn to set yourself up and feel great anywhere.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'overnight_travel' }">
                              <img src="../assets/images/travel-for-work/overnight-travel.jpg" alt="img"
                                 class="mb_66">                              
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Overnight Travel</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Sleep well, eat clean and keep up your self-care routines.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    10 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'working_on_the_go' }">
                              <img src="../assets/images/travel-for-work/working-on-the-go.jpg" alt="img"
                                 class="mb_66">
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Working On The Go</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Did you know that it is easy to set your self up wherever you go?
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'self_care' }">
                              <img src="../assets/images/travel-for-work/self-care.jpg" alt="img"
                                 class="mb_66">
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Self Care</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Don’t let your travels disrupt your healthy routines.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'feel_good_stretches' }">
                              <img src="../assets/images/travel-for-work/feel-good-stretches.jpg" alt="img"
                                 class="mb_66">
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Feel Good Stretches</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Treat your body to these feel good stretches anytime, anywhere.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    10 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="take_an_ergonomic_parttwo mb_44" v-show="company_code">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xl-10">
                  <div class="ergonomic-image pt_26 pb_26 ">
                     <div class="overlay">
                        <div class="row">
                           <div class="col-md-12 col-lg-7 col-xxl-6">
                              <div class="left-section pl_60 pr_60">
                                 <h6 class="sub_heading_1 font_bold mb_22 text-white">Need more help?
                                 </h6>
                                 <p class="text-white p_20 mb_54">Take a self-assessment today and see how ergo can help you feel better and work smarter.</p>
                                 <div class="mt_14">
                                    <router-link :to="{ name: 'self_assessment_signup' }"
                                       class="btn btn_secondary font_size_16">Take a Self-Assessment</router-link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>     
   </div>
</template>

<script>
   export default {
  data () {
    return {  
      company_code: true,     
    }
  },
      name: "Travel-for-work",
      mounted(){ 
         document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;   
         let data = this.$store.getters["auth/authUser"];
         let company_data = data.client;
         if(company_data.self_assessment_button_status == 0){
            this.company_code = false;
         }
         if (
            localStorage.getItem("userToken") == null ||
            localStorage.getItem("employeeEmail") == null
         ) {
            this.$router.push({ name: "login" }).catch(() => true);
         } 
      }      
   }
</script>